.App {
  text-align: center;
}

.center {
  text-align: center;
  vertical-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.red-alert-hyphen  {
  color: red;
  font-size: 200%;
}

.red-alert-small  {
  color: red;
}

.label {
  color:  black;
  margin-bottom: 0px;
  font-weight: bold;
  font-style: italic;
}

.form-group {
  margin-bottom: 20px;
  margin-bottom: 5px;
  margin-right: 5px;
}

.btn-space-right {
  margin-right: 5px;
}

.btn-space-left {
  margin-left: 5px;
}


.crosssighns {
  z-index: 1;
  font-size: 1.1em;
  margin-left: 5px;
  margin-top: 3px;
  cursor: pointer;
  float: right;
}


.box {
  display: flex;
  align-items: center;
  justify-content: center;
}