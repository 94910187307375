html {
    min-width: 320px;
}
.App {
    text-align: center;
  }
  
  .center {
    text-align: center;
    vertical-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  
  .red-alert-hyphen  {
    color: red;
    font-size: 200%;
  }
  
  .red-alert-small  {
    color: red;
  }
  
  .label {
    color:  black;
    margin-bottom: 0px;
    font-weight: bold;
    font-style: italic;
  }
  
  .form-group {
    margin-bottom: 20px;
    margin-bottom: 5px;
    margin-right: 5px;
  }
  
  .btn-space-right {
    margin-right: 5px;
  }
  
  .btn-space-left {
    margin-left: 5px;
  }
  
  
  .crosssighns {
    z-index: 1;
    font-size: 1.1em;
    margin-left: 5px;
    margin-top: 3px;
    cursor: pointer;
    float: right;
  }
  @font-face {
    font-family: 'BuenosAires';
    src: local('BuenosAires'), url(./../fonts/BuenosAiresWeb-Book.woff) format('woff');
    }
    @font-face {
        font-family: 'BuenosAiresBold';
        src: local('BuenosAiresBold'), url(./../fonts/BuenosAiresWeb-Bold.woff) format('woff');
        }
        @font-face {
            font-family: 'BuenosAiresSemiBold';
            src: local('BuenosAiresSemiBold'), url(./../fonts/BuenosAiresWeb-SemiBold.woff) format('woff');
            }
  .bbBG {
      background-color: #f3f3f3;
      overflow: hidden;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      &>.row {
        max-height: 396px;
      }
  }
  .bbWidget {
    overflow: hidden;
    transition: all 0.2s linear;
    padding: 0;
    background: #f3f3f3;
    font-family: BuenosAires;
    position: relative;
    height: 396px;
    margin: 0 auto;
    display: flex;
    flex-flow: column;
    max-width: 100%;
    max-width: 653px;
    @media (min-width: 375px){
        height: 396px;
    }
    @media (min-width: 540px){
      height: 396px;
  }
    @media (min-width: 540px){
        height: 396px;
    }
    @media (min-width: 1440px){
        height: 396px;
    }
    &__inner {
        margin: 0px auto;
        position: relative;
        padding: 45px 25px;
        justify-content: center;
        &--selection {
            padding: 25px;
            @media (max-width: 540px){ 
              padding: 15px 25px 15px;
            }
        }
        &--budgetSelect {
          padding: 20px;
          @media (max-width: 540px){
            margin-bottom: 0px;
            padding: 15px 25px;
            .bbText--smallTitle {
              margin-bottom: 10px;
            }
          }
          .bbText--smallTitle {
            margin-bottom: 15px;
          }
          .bbWidget__inner--nav {
            margin-top: 15px;
          }
        }
        &--results {
          padding: 20px;
        }
        &--confirmed {
          padding: 15px;

          @media (min-width: 540px){
            padding: 45px 50px;
          }
          .row {
            max-width: 646px;
          }
          .bbText {
            width: 100%;
            @media (min-width: 540px){
              flex: 0 0 66.666667%;
              max-width: 66.666667%;
            }
            padding: 0 15px;
            display: flex;
            position: relative;
            justify-content: center;
            flex-direction: column;
            &--smallTitle {
              color: #2a3445;
              font-size: 22.4px;
              font-family: BuenosAiresBold;
              text-align: center;
              span {
               font-family: BuenosAires;
              }
              @media (min-width: 540px){
                text-align: left;
              }
            }
            &--absoluteBL {
              font-size: 13px;
              text-align: center;
              margin-top: -15px;
              margin-bottom: 20px;
              @media (min-width: 540px){
                font-size: 16px;
                margin: 0;
                text-align: left;
              }
            }
          }
        }
        border-radius: 8px;
        box-shadow: 0 16px 40px 0 rgba(0, 0, 0, 0.1);
        background-color: #f3f3f3;
        line-height: 1.2;
        flex:1;
        display: flex;
        flex-flow: column;
        &--nav {
            display: inline-block;
            width: 100%;
            margin-top: 30px;
        }
        &--edit {
          width: 100%;
          min-height: 787px;
          .cardImageOverlayCustomiztion {
            margin: 48px 18px 38px 18px;
          }
          .mobilePreviewCard.card { 
            margin: -40px auto -139px;
            .header .title {
              margin-top: 8px;
              margin-bottom: 5px;
            }
          }
          .form-group {
            margin-bottom: 15px;
            width: 100%;
            label {
              font-family: BuenosAiresBold;
              font-size: 14px;
              font-style: normal;
              margin-bottom: 5px;
            }
            input,textarea {
              border: none;
              font-family: BuenosAires;
              font-size: 14px;
              padding: 12px 16px;
              border-radius: 8px;
              resize: none;
            }
          }
        }
    }
    &__copy {
        display: inline-block;
        width: 100%;
        padding: 15px 0px;
        span {
            display: inline-block;
            vertical-align: middle;
        }
        &--bb {
            width: 49%;
            font-family: BuenosAiresBold;
            font-size: 27px;
            text-align: left;
        }
        &--bbCopyright {
            width: 51%;
            font-size: 10px;
            text-align: Right;
        }
    }
    &__button {
        transition: all 0.2s ease-in-out;
        padding: 16px 25px;
        border-radius: 5px;
        background-color: #75d0d4;
        border: 1px solid #75d0d4;
        line-height:1;
        font-size: 16px;
        outline: none;
        width: 179px;
        height: 48px;
        &:hover {
            background-color: #75d0d4;
            border-color: #2a3445;
        }
        &--next, &--prev {
            width: auto;
            float: right;
            height: 48px;
            margin: 0;
            color: #FFF;
            font-size: 16px;
            position: relative;
            svg {
              position: absolute;
              top: 18px;
              height: 12px;
          }
          @media (max-width: 540px){
            width: 100%;
            margin: 5px auto;
            height: 41px;
            padding: 9px 20px;
            svg {
              top: 14px;
            }
          }
        }
        &--prev, &--cancel {
            float: left;
            background-color: transparent;
            color: #2a3445;
            border: 1px solid #2a3445;
            &:hover {
                background-color: transparent;
            }
            svg {
              left: 5px;
            }
        }
        &--next {
            svg {
              right: 5px;
            }
        }
        &--edit {
          width: 179px;
          height: 48px;
          font-family: BuenosAires;
          font-size: 16px;
          background-color: #202b36;
          color: #FFFFFF;
          padding: 5px;
          border-color:#202b36;
          margin-left: -13px;
          &:hover {
            background-color: #202b36;
            border-color: #202b36;
          }
        }
        &--cancel {
          width: 113px;
          height: 48px;
          font-size: 16px;
        }
        &--save {
          width: 181px;
          height: 48px;
          font-size: 16px;
          padding: 16px;
          white-space: nowrap;
        }
     }
    &__package {
        transition: all 0.2s ease-in-out;
        position: relative;
        max-width: calc(100% - 30px);
        padding: 10px;
        margin: 0 15px 0;
        width: 100%;
        height: 80px;
        vertical-align: middle;
        border-radius: 8px;
        background-color: #ffffff;
        border: 1px solid;
        outline: none;
        box-shadow: none;
        border-color: transparent;
        color: #202b36;
        font-size: 20px;
        @media (max-width: 540px){ 
          &+.bbWidget__package:not(.bbWidget__package--budget) {
            margin: 15px 15px 0;
          }
        }
        &:hover {
            cursor: pointer!important;
            border-color:#f9ab93;
        }
        &.disabled {
          cursor: not-allowed!important;
          background: #c4c4c4;
          border: #c4c4c4!important;
        }

        &--selected {
            background-color: #f9ab93;
            .bbWidget__budgetText--reach {
              color: #202b36;
            }
        }
        &--text {
            display: block;
            text-align: center;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            font-size: 20px;
            font-family: BuenosAiresBold;
        }
        svg {
          position: absolute;
          top: 8px;
          right: 10px;
        }
        @media (min-width: 540px) {
            margin: 0 15px;
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: calc(50% - 30px);
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
        &--budget {
          height: 100%;
          max-height: 70px;
          padding: 5px 10px;
          margin: 5px 15px 0;
          @media (min-width: 540px) {
            height: 100%;
            max-height: 140px;
            padding: 5px 10px;
            margin: 0 15px;
            -ms-flex: 0 0 33.333333%;
            flex: 0 0 33.333333%;
            max-width: calc(33.333333% - 30px);
            position: relative;
            top: 50%;
            transform: translateY(-50%);
          }
        }
    }
    &__budgetText {
      text-align: center;
      width: 100%;
      display: inline-block;
      color: #103c96;
      &--name {
        font-family: BuenosAiresBold;
        font-size: 16px;
        color: #202b36;
        margin-top: 10px;
        margin-bottom: 8px;
      } 
      &--price {
        font-family: BuenosAiresBold;
        font-size: 20px;
        margin-bottom: 23px;
        color: #202b36;
      }
      &--reach {
        font-family: BuenosAires;
        font-size: 14px;  
      }
      span {
        font-size: 12px;
        width: 100%;
        display: inline-block;
      }
      @media (max-width: 540px) { 
        width: 50%;
        &--name {
          margin-bottom: 0;
        }
        &--price {
          margin-bottom: 0;
        }
        &--reach {
          width: 100%;
          span {
            display: inline;
            width: auto;
            margin-left: 10px;
          }
        }
      }
    }
    &__progress {
      background: #FFFFFF;
      border-radius: 8px;
      padding: 4px 5px;
      position: relative;
      overflow:visible;
      margin-bottom: 25px;
      .bg-brightBee {
        background-color: #f9ab93;
        border-radius: 8px;
      }
      &:before {
        position: absolute;
        content: 'Start';
        bottom: -20px;
        left: 0;
      }
      &:after {
        position: absolute;
        content: 'Slut';
        bottom: -20px;
        right: 0;
      }
    }
    &__bbPhone {
      background-image: url('./../images/BrightBeePhone.png');
      background-repeat: no-repeat;
      background-size: 120px 222px;
      background-position: top center;
      text-align: center;
      padding-top: 71px;
      padding-left: 0px;
      padding-right: 0px;
      width: 184px;
      max-height: 231px;
      margin: 0 auto;
      @media (min-width: 540px){
        text-align: left;
      }
      &--inner {
        height: 77px;
        margin: 0 auto;
        width: 109px;
      }
      img {
        display: block;
        margin: 26px auto 0px;
        max-width: 110px;
      }
      button {
        margin: 0 auto;
        display: block;
      }
    }
    &--edit {
      max-height: 100%;
      height: auto;
      min-width: 662px;
      margin: 0;
      overflow-y: auto;
    }
    &__edit {
      &--buttons {
        margin-top: 50px;
        width: 100%;
        text-align: center;
        .bbWidget__button--cancel {
          float: left;
        }
        .bbWidget__button--save {
          float: right;
        }
        svg {
          position: absolute;
          top: 14px;
          right: 0;
          font-size: 35px;
        }
      }
    }
    &__selectedAreas {
      &--area {
        display: inline-block;
        margin: 8px 24px 8px 0px;
        padding: 8px 40px 8px 16px;
        border-radius: 8px;
        background-color: #ffdfda;
        position: relative;
        width: 204px;
        height: 40px;
        input.form-control:disabled {
          background: transparent;
          border: none;
          padding: 0;
          height: 100%;
          line-height: 1;
          outline: none;
          font-family: BuenosAiresBold;
          font-size: 13px;
        }
        .crosssighns {
          position: absolute;
          line-height: 1;
          margin: 0;
          float: none;
          right: 0;
          padding-left: 6px;
          width: 40px;
          font-size: 26px;
          padding-top: 4px;
          top: 0;
          height: 100%;
        }
      }
    }
    &__areaInput {
      label {
        font-family: BuenosAiresBold;
        font-size: 14px;
        margin-top: 12px;
        margin-bottom: 5px;
      }
      input {
        max-width: 233px;
      }
      .react-autosuggest__container {
        margin-bottom: 30px;
      }
      .react-autosuggest__suggestions-container {
        display: block;
        max-height: 110px;
        position: absolute;
        overflow-y: auto;
        z-index: 99;
        border-radius: 8px;
        max-width: 233px;
        background: #FFF;
        ul {
          list-style: none;
          padding: 0;
          li {
            border-bottom: 1px solid #000;
              padding: 3px 8px;
              h6 {
                margin: 0;
                b {
                  font-size: 12px;
                }
              }
            &:hover {
              background: #f0f0f0;
            }
          }
        }
      }
    }
  }
 .bbText {
    text-align: left;
     span {
        display: inline-block;
        width: 100%;
        color: #2a3445;
     }
     &--title {
        font-size: 25.6px;
        font-weight: 700;
        font-family: BuenosAiresBold;
        margin-bottom: 8px;
     }
     &--description, &--confirmed, &__result, &__stats--statNumber {
        font-size: 22.4px;
     }
     &--editTitle {
       font-size: 22.4px;
       color: #2a3445;
       display:inline-block;
       margin-bottom: 20px;
     }
     &--areaTitle {
       font-size: 20px;
       font-family: BuenosAiresBold;
       width: 100%;
       display: inline-block;
       margin-top: 51px;
     }
     &--confirmed, &__stats--statNumber {
       font-family: BuenosAiresBold;
     }
     &--confirmed {
      font-size: 25.6px;
       position: relative;
       text-align: center;
       @media (min-width: 540px){
         text-align: left;
       }
       svg {
         position: relative;
         top: -3px;
         color: #91e364;
       }
     }
     &__result {
       margin-bottom: 10px;
       &--name {
         font-family: BuenosAiresBold;
         font-size: 20px;
         @media (min-width: 540px){
          font-size: 25.6px;
         }
       }
       &--progress {
         margin-bottom: 25px;
         font-size: 20px;
         @media (min-width: 540px){
          font-size: 25.6px;
         }
       }
     }
     &--smallTitle {
          color: #2a3445;
         font-size: 22.4px;
         margin-bottom: 30px;
     }
     &__stats {
      margin-top:20px;
      text-align: center;
      span {
        width: 100%;
      }
      &--statText {
        font-size: 16px;
      }
      &--stat {
        margin-bottom: 10px;
      }
      @media (min-width: 540px){
        text-align: left;
        display: flex;
        justify-content: space-between;
        span {
          width: auto;
          display: block;
        }
        &--stat {
          margin-bottom: 0;
        }
      }
  }
  &--link {
    vertical-align: middle;
    display: inline-block;
    margin-top: 13px;
    color: #2a3445;
    font-size: 14px;
    text-decoration: underline;
  }
 }
